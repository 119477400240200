.bio-hero {
    background-image: url('/assets/img/bio-slider-3-1.jpg'); 
    @media(max-width:1200px){
        height: 220px !important; 
    }
    @media(max-width:800px){
        height: 165px !important; 
    }
    @media(max-width:350px){
        height: 210px !important; 
    }
    h1 {
        font-family: $font-family; 
        font-size: 40px; 
        font-weight: normal; 
        @media(max-width:800px){
            margin-top: 25px !important; 
        }
    }

    a {
        text-decoration: none; 
        font-style: italic; 
        color: $light; 
        font-size: 15px !important; 
    }

    p {
        font-size: 15px; 
        margin-bottom: 20px; 
        @media(max-width:800px){
            margin-bottom: 0px; 
        } 
    }

    .hero-hz-line {
        width: 130px; 
        border-width: 3px; 
        @media(max-width:500px){
            border-width: 1px; 
        }
    }
  
    .big {
        font-size: 17px; 
        margin-top: 10px;
        margin-bottom: 0px; 
    }
}

.bio-content {
    padding-bottom: 50px; 
    p {
        margin-bottom: 15px; 
        font-size: 15px; 
        line-height: 1.6em; 
    }
    .hz-line{
        border-bottom: $primary solid 3px; 
        width: 300px; 
        height: 1px; 
        margin-bottom: 60px;
        margin-top: 60px;
        @media(max-width: 1200px){
            margin-top: 40px; 
            margin-bottom: 40px; 
            
        }
        @media(max-width: 500px){
            margin-top: 20px; 
            margin-bottom: 20px; 
            
        }
        @media(max-width:500px){
            width: 280px;
        }
    }
   
}