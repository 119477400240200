.side-bar{
    background-color: #f4f4f4; 
    padding-top: 70px; 
    padding-left: 40px; 
    @media(max-width: 799px) {
        background-color: #fff; 
    }
    .sub-heading{
        color: $primary-2;
        font-family: $font-family-secondary;
        font-weight: bold; 
        font-size: 13px; 
        margin-bottom: 30px; 
        letter-spacing: 4px;  
        @media(max-width:700px){
            font-size: 1.5rem;
            font-weight: normal;  
        }
    }
}


.featured-post {
    p {
        line-height: 22px; 
        transition: color .3s ease-in-out;
        font-style: italic !important; 
        border-bottom: 1px solid #e8e8e8; 
        cursor: pointer !important;
        max-width: 250px; 
        &:hover{
            color: $primary-2; 
        }
        @media(max-width:800px){
            max-width: 100%; 
            line-height: 25px; 
        }
    }
}


.posts {
    border-top:  #e8e8e8 solid 1px; 
    padding-top: 30px; 
    @media(max-width:800px){
        border-top: none; 
        padding-top: 0px; 
    }
    .post {
        min-height: 350px; 
        border-bottom: #e8e8e8 dotted 1px; 
        padding: 70px 0px; 
        margin-left: 90px !important; 
        margin-right: 30px !important; 
        @media(max-width:800px){
            padding: 30px 0px 150px 0px; 
            margin-left: 0px !important; 
            margin-right: 0px !important; 
        }
        @media(max-width:500px){
            padding: 70px 0px 70px 0px; 
        }
        .title{
            font-family: $font-family-secondary;
            color: $primary-2; 
            font-size: 18px !important; 
            font-weight: bold;
            &:hover{
                transition: none !important; 
            }

        }
        img {
            max-width: 100%; 

        }
        a {
            display: block; 
            font-style: normal; 
            font-size: 12px !important; 
            font-family: $font-family-secondary; 
            font-weight: bold; 
            &:hover {
                color: $primary-2; 
                transition: color .3s ease-in-out, text-shadow .3s ease-in-out; 
            }
        }
    }
}

.pagination-box {
    height: 40px;
    line-height: 40px;
    font-family: $font-family-secondary;
    font-size: 11px; 
    color: $primary-2; 
    font-weight: 600; 
    .prev-next-links {

        padding-left: 25px; 
        padding-right: 25px; 
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
        border-left: 1px solid #e8e8e8;
        transition: color .3s ease-in-out;
        &:hover{
            color: $primary; 
        }
    }
    .page-number {
        padding-left: 11px; 
        padding-right: 11px; 
        min-width: 42px; 
        transition: color .2s ease;
        border-top: 1px solid #e8e8e8; 
        border-bottom: 1px solid #e8e8e8;
        &:hover{
            color: $primary; 
        }
        &.page-2{
            border-right: 1px solid #e8e8e8;
        }
        &.page-1{
            border-left: 1px solid #e8e8e8;
        }
    }

    .active {
        background-color: #1b1b1b;
        color: $primary; 
        border: none; 
    }
}

    ul {
        &.search-results li { 
            padding: 1rem 0;
            a {
            line-height: 22px !important; 
            transition: color .3s ease-in-out !important;
            font-style: italic !important; 
            font-size: 14px;
            font-family: Montserrat !important; 
            cursor: pointer !important;
            max-width: 250px !important; 
            color: #8e052a !important;
            text-decoration: none;
            &:hover {
                color: #8e052a !important;
                }
            }
        }
    }

    .search-bar:not(.search-nav) {
        background-color: #f4f4f4; 
            input[type="text"] {
            margin: 0;
            padding: 0;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            font-family: "Montserrat";
            line-height: 25px;
            text-transform: none;
            letter-spacing: 0px;
            word-spacing: 0;
            color: #5a5a5a;
            background-color: #fff;
            color: #565656;
            padding: 7px 15px 7px;
            width: 100%;
            display: block;
            font-size: 14px;
            height: 56px;
            border-radius: 0;
            border: 1px solid #e8e8e8;
            }
            button[type="submit"] {
                width: 56px;
                height: 56px;
                display: block;
                right: 10px;
                text-align: center;
                svg {
                    fill: #D5D5D5 !important;
                    }
                }
            @media(min-width:801px){
                button[type="submit"] {
                    position: absolute !important;
                    top: 119px !important;
                    right: 11px;
                }
            }
        @media(max-width: 800px){
            background-color: #fff;
            padding: 0;
            button[type="submit"] {
                position: absolute;
                top: 119px !important;
            }
            @media(max-width: 701px){
                button[type="submit"] {
                    position: absolute;
                    top: 135px !important;
                }
            }
        }
    }
