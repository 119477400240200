.intro-block{
    border: $primary solid 10px; 
    padding: 3%;
    position: absolute; 
    top: 200px; 
    background-color: $light;  
    p {
        line-height: 1.6;
        margin-bottom: 1.07em; 
    }
    span{
        background-color: $primary; 
        color: $light; 
        font-size: 200%; 
        display: inline-block;
        line-height: 2; 
        text-align: center;
        margin-right: 10px; 
        height: 2em; 
        width: 2em; 
    }
    @media(max-width:800px){
        top: 250px; 
    }
    @media(max-width:500px){
        top: 150px; 
    }
}

.firm-overview-content-box {
    @media(min-width:751px){
        padding-top: 300px; 
    }
    @media(max-width: 750px){
        padding-top: 400px; 
    }
    @media(max-width: 560px){
        padding-top: 425px; 
    }
    @media(max-width: 390px){
        padding-top: 475px; 
    }
}


.overview-content {

    h2 {
        font-family: $font-family; 
        color: $primary-3; 
        font-size: 18px; 
    }

    a {
        font-family: $font-family; 
        text-decoration: none; 
        color: $primary;
        font-size: 15px;
    }
    .person-text-box {
        a {
            font-style: none !important; 
        }
    }
    p {
        margin-bottom: 0px;
    }

    img {
        width: 100%;
    }

    .hz-line{
        border-bottom: $primary solid 3px; 
        width: 300px; 
        height: 1px; 
        margin-bottom: 40px;
    }

    .hz-line-short {
        border-bottom: $primary solid 1px; 
        width: 100px; 
        height: 1px; 
        margin: 15px auto; 
    }

}



.firm-management {
    img {
        background-color: #262626 !important; 
    }
    @media(max-width:1200px){
        .col-md-3{
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
    }
}

.firm-management-box {
    background-color: $primary-2; 
    padding: 20px; 
    font-style: normal;
    height: 271px; 

    p {
        text-align: left; 
        font-size: 17px; 
        font-weight: 525;
    }
    
    @media(max-width:1200px){
        height: 190px; 
        width: 170px;
        padding: 15px; 
        p {
            font-size: 12px; 
            line-height: 14px;
        } 
    }
}

.person-text-box {
    .person-text {         
        background:#262626;         
        font-family: 'Montserrat';         
        min-height: 304px !important;
        p {             
            font-size: 14px !important;        
            @media(max-width: 1300px) {
                font-size: 13px !important;    
            }
            @media(max-width: 950px) {
                font-size: 12px !important;    
            }
            font-style: none !important; 
            font-weight: 600;
            line-height: 1.4;
        }         
        .hz-line-long {             
            width: 87%;             
            height: 3px;             
        }     
    } 
}


@media(max-width: 799px) {
    .person-text-box {
        .person-text {         
            background:#262626;         
            font-family: 'Montserrat';         
            p {             
                font-size: 30px !important;        
                font-style: none !important; 
                font-weight: 600;
                line-height: 1.4;
            }         
            .hz-line-long {             
                width: 87%;             
                height: 8px;               
            }     
        } 
    }
}

@media(max-width: 500px) {
    .person-text-box {
        .person-text {         
            background:#262626;         
            font-family: 'Montserrat';         
            p {             
                font-size: 20px !important;        
                font-style: none !important; 
                font-weight: 600;
                line-height: 1.4;
            }         
            .hz-line-long {             
                width: 87%;             
                height: 4px;               
            }     
        } 
    }
}

.person-image-box {
    position: relative;
    overflow: hidden;
    max-width: 100%;
    .bio-image {
        position: relative;
        .overlay-content-charles-b-mckenna,
        .overlay-content-j-la-perla,
        .overlay-content-kbeirne, 
        .overlay-content-kbarrows,
        .overlay-content-gcaulfield {
            background: rgba(0, 0, 0, 0.6);
            position: absolute;
            width: 100% !important;
            height: 100% !important;
            overflow-y: scroll;
            top: 0;
            font-family: $font-family !important;
            font-style: normal !important;
            font-size: 16px !important;
            left: 1;
            cursor: pointer !important;
            color: #fff;
            left: 120%;
            transition-duration: 0.3s;
        hr {
            @media(min-width: 800px) {
                margin: 1rem;
            }
            @media(max-width: 799px) {
                margin: 2rem;
            }
            width: 80%;
            border: 1px solid #fff;
        }
        p,
        span {
            font-family: $font-family;
            font-style: normal;
            cursor: pointer;
        }
        p {
            font-size: 16px;
        }
        span {
            font-size: 13px;
        }
    }
        &:hover {
            .overlay-content-charles-b-mckenna,
            .overlay-content-j-la-perla,
            .overlay-content-kbeirne,
            .overlay-content-kbarrows,
            .overlay-content-gcaulfield {
                left: 0;
            }
        }
    }
}