#contact-desktop {
    display: block; 
    @media(max-width:800px){
        display: none; 
    }
}

#contact-mobile {
    h2 {
        font-family: $font-family; 
        font-size: 40px; 
        color: $primary-2;
        margin-bottom: 20px; 
        @media(max-width:500px){
            font-size: 24px; 
        }
    }
    p {
        margin-bottom: 0px; 
    }
    .hz-line {
        border-bottom: $primary solid 1px; 
        width: 50px;   
        height: 1px; 
    }
    display: none; 
    @media(max-width:800px){
        display: block; 
    }
}

.contact-content-background {
    height: 400px; 
    @media(max-width:800px){
        height: 100%; 
    }
}

.location-blocks-wrapper {
    background-color: transparent; 
    position: absolute;
    width: 100%; 
    height: 320px; 
    top: 240px;
    @media(max-width:1200px){
        top: 400px;
    }
}

.more-padding-left{
    @media(min-width:1201px){
        padding-left: 200px !important; 
    }
}
.more-padding-right{
    @media(min-width:1201px){
        padding-right: 210px !important; 
    }
}

.location-block {
    border: $primary solid 10px; 
    max-width: 283px; 
    height: 310px; 
    background-color: #1e1e1e; 
    overflow: hidden;

    &:hover {
        img {
            opacity: 0.1; 
            transition: 0.4s;
        }
    }
    
    &.dc-container {
        text-align: right; 
    }

    img {
        min-width: 263px; 
        max-width: 263px;
        opacity: 0.3; 
    }
}

.location-label {
    background: transparent; 
    max-width: 263px;
    min-width: 263px;
    height: 320px;  
    overflow: hidden; 
    position: absolute;
    top: 20px; 
    &:hover h2 {
        top: 100px;
    }
    &:hover i.fa.fa-caret-down {
        bottom: 70px;
        transition: 0.4s ease-in-out;
        opacity: 1;
    }
    h2 {
        font-family: $font-family; 
        font-weight: bold; 
        font-size: 26px; 
        color: $light;
        position: absolute;
        left: 5;
        top: 120px;
        left: 47px;
        transition: 0.4s;
        &.wash-dc {
            left: 12px;
        }
        &.new-york {
            left: 55px;
        }
    }
    i.fa.fa-caret-down {
        font-size: 30px;
        color:#fff !important;
        position: absolute;
        bottom: 50px;
        transition: 0.3s ease-in-out;
        left: 125px;
        opacity: 0;
    }
}

.info-block {
    width: 283px;
    white-space: nowrap;
    overflow: visible;
    position: relative; 

    p {
        font-family: $font-family; 
        font-size: 12.6px; 
        color: $primary-3;
    }
    span {
        border-bottom: $primary-3 solid 1px; 
        width: 50px; 
        float: left; 

        &.right {
            float: right;
        }
    }

    .left {
        display: inline;
        float: left;
    }
    
    div {
        &.right {
            text-align: end;
            display: inline;
            float: right;
        }

    }
}