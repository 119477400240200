.news-button {
    font-size: 14px !important; 
    line-height: 14px; 
    border: 2px solid $light; 
    padding: 8px 30px; 
    font-family: $font-family;
    display: block; 
    width: fit-content; 
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
    &:hover {
        background-color: $light; 
        transition: all 0.3s ease-in-out; 
        p{
            transition: all 0.3s ease-in-out;
            color: #1b1b1b !important; 
        }
    }
    p {
        margin-bottom: 0px !important; 
    }

}

.news-post-hero{
    background-image: url('/assets/img/1440-news_slider.jpg');
    height: 300px !important; 
    @media(max-width:800px){
        h1 {
            font-size: 43px !important; 
            margin-bottom: 0px !important; 
        }
        .hero-hz-line{
            width: 124px !important;
            border-bottom-width: 3px !important;  
        }
        height: 170px !important; 
    }
    @media(max-width:500px){
        height: 100px !important; 
        h1 {
            font-size: 24px !important; 
        }
        .hero-hz-line{
            width: 70px !important; 
            border-bottom-width: 2px !important; 
        }
    }
}

.news-post {
    img {
        max-width: 100%;
        max-height: 100%; 
    }
    p {
        margin-bottom: 20px; 
    }

    a {
         
        font-style: italic !important; 
        &:hover {
            color: $primary;
        }
    }
    li {
        font-size: 14px;  
        font-family: $font-family; 
    }
    .text-box{
        padding-right: 110px !important; 
        @media(max-width:800px){
            padding-right: 15px !important; 
        }
    }
    .hz-line {
        border-bottom: $primary solid 3px; 
        width: 100px;   
        height: 1px;
    }
}

.fixed-arrow-bar{ 
    position: fixed; 
    background-color: transparent; 
    z-index: 1;
    top: 50%; 
    @media(max-width:1280px){
        display: none !important; 
    }
    a{
        height: 48px; 
        color: $light; 
        background-color: #1b1b1b; 
        line-height: 48px; 
        width: 20px; 
    }
    .previous{
        float: left; 
    }
    .next{
        float: right; 
    }
}