.footer {
    z-index: 2; 
    position: relative; 
}
.contact-block{
    background-color: $secondary; 
    
    .heading-spacer{
        height: 74px; 
    }

    .contact-header {
        
        p {
            font-family: $font-family-display; 
            font-style: italic; 
            font-size: 16px;
            line-height: 25px; 
        }
    }

    .contact-header-subtext {
        @media(max-width: 500px){
            padding-top: 10px; 
            padding-bottom: 45px; 
            font-size: 25px !important; 
        }
    }

    .white-line { 
        margin-bottom: 5px;
        
        span {
            background-color: $secondary; 
            border-bottom-color: $light; 
            border-bottom-style: solid; 
            border-bottom-width: 1px; 
            width: 50px; 
            margin: 0px auto; 
        }
    }

    .phone-cards-wrapper{
        margin-top: 35px; 
        margin-bottom: 75px; 
    }

    .location-image {
        border-radius: 5%; 
    }

    .phone-cards{
        font-family: $font-family; 
        font-size: 16.8px; 

    }

}

.copywrite {
    height: 74px; 
    background-color: #1E1E1E;
    overflow-y: hidden; 

    p {
        line-height: 74px; 
        font-size: 13px !important; 
        color: rgba(255,255,255,0.6);
        margin-bottom: 0px; 
    }
}