$grid-breakpoints: (
  xs: 0,
  sm: 500px,
  md: 800px,
  lg: 1200px,
  xl: 1400px
);

@import "bootstrap/bootstrap";

/* Global Variables */

// Fonts

$font-family-display: 'Old Standard TT', serif; 
$font-family: 'Montserrat', sans-serif; 
$font-family-secondary: 'Dosis', sans-serif; 

// Theme Color Scheme 

$primary:       #8e052a;
$primary-2:     #28262B;
$primary-3:     #5a5a5a;
$light:         #FFFFFF;
$danger:        #ed3f54;
$secondary:     #242424;      
// Do not change or remove the $theme-color-scheme or $theme-colors maps below

$theme-color-scheme: (
   "primary":    $primary,
   "primary-2":  $primary-2,
   "primary-3":  $primary-3,
   "danger":     $danger,
   "secondary":  $secondary
   );

$theme-colors: (
   "primary":    $primary,
   "primary-2":  $primary-2,
   "primary-3":  $primary-3,
   "danger":     $danger,
   "secondary":  $secondary
);

.bg-primary{
    background: $primary !important; 
}
.bg-primary-2{
    background: $primary-2 !important; 
}
.bg-primary-3{
    background: $primary-3 !important; 
}
.bg-light{
    background: $light !important; 
}
.bg-secondary{
    background: $secondary !important; 
}

.text-primary{
    color: $primary !important; 
}
.text-primary-2{
    color: $primary-2 !important; 
}

.text-primary-3{
    color: $primary-3 !important; 
}

.text-light{
    color: $light !important; 
}
.text-secondary{
    color: $secondary !important; 
}

/* Partial Imports */

@import "custom_partials/nav";
@import "custom_partials/footer";
@import "custom_partials/home";
@import "custom_partials/contact";
@import "custom_partials/firm-overview";
@import "custom_partials/bio";
@import "custom_partials/careers";
@import "custom_partials/services";
@import "custom_partials/post";
@import "custom_partials/news-and-events";

/* Base Styles */

html, body {
    color: #000;
    max-width: 100%; 
    overflow-x: hidden;
}

html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased; }

.container {
    padding: 0px; 
}

.row {
    margin-left: 0px !important; 
    margin-right: 0px !important;
}

h1 {
    color: $light; 
    font-family: $font-family-display; 
    font-size: 70px; 
    font-weight: bold; 
}

h2 {
    $color: $primary-2; 
    font-family: $font-family; 
    font-size: 16px; 
}

h3 {
    color: $light; 
    font-family: $font-family; 
    font-size: 40px; 
}

h4 {
    font-family: $font-family-secondary; 
    font-size: 14.4px; 
}

h5 {
    color: $light; 
    font-family: $font-family; 
    font-size: 25px; 
    line-height: 45px;
}

p {
    color: $primary-3; 
    font-family: $font-family; 
    font-size: 14px; 
    line-height: 1.6; 
}

a {
    color: $primary; 
    text-decoration: none !important;
    cursor: pointer; 
}

.container {
    max-width: 100%; 
}

/* Landing Page Hero Styles */

.hero {
    height: 350px; 
    background-position: center center; 
    background-size: cover; 
    &.firm-overview {
        height: 300px !important;
    }
    @media(max-width:1200px) {
        height: 576px; 
    }
    @media(max-width:500px){
        height: 100px; 
    }
}

.hero-title-adjustment {
    margin-top: 110px; 
    margin-bottom: 0px; 
    &.firm-overview {
        margin-top: 80px;
        @media(max-width:1200px){
            margin-top: 50px; 
        }
        @media(max-width:500px){
            margin-top:35px; 
        }
    }
    @media(max-width:1200px){
        font-size: 58px;
        margin-top: 230px; 
    }  
    @media(max-width:500px){
        margin-top: 35px;
        font-size: 24px; 
        &.contact {
            margin-top: 35px; 
        }
    }
}

.hero-hz-line {
    width: 200px; 
    height: 1px; 
    border-bottom: $primary solid 5.5px; 
    transition: none 0s ease 0s;
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    transform-origin: 50% 50% 0px;
    @media(max-width:1200px){
        width: 165px; 
        border-bottom-width: 4px; 
    }
    @media(max-width:800px){
        width: 150px; 
        border-bottom-width: 4px;
    }
    @media(max-width:500px){
        width: 70px; 
        border-bottom-width: 2px; 
    }
}

/* Hover.css effects */

// Sweep To Bottom 
.hvr-sweep-to-bottom {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    -webkit-transition-property: color;
    transition-property: color;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  .hvr-sweep-to-bottom:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $primary;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  .hvr-sweep-to-bottom:hover, .hvr-sweep-to-bottom:focus, .hvr-sweep-to-bottom:active {
    color: $light;
  }
  .hvr-sweep-to-bottom:hover:before, .hvr-sweep-to-bottom:focus:before, .hvr-sweep-to-bottom:active:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }

  //scroll-to-top btn
    .go-to-top {
        opacity: 0;
        position: fixed;
        bottom: -50px;
        right: 40px;
        width: 45px;
        height: 45px;
        z-index: 9999;
        background-color: #e8e8e8;
        border: 0px solid #fff;
        border-radius: 3px;
        transition: 0.3s ease-in-out;
        svg {
            fill: #000;
        }
        &.top-btn-show {
            opacity: 1;
            transition: 0.3s ease-in-out;
            bottom: 60px;
            svg {
                top: 10 !important;
                left: 11 !important;
                }
                &:hover {
                    background-color: #000;
                    transition: 0.3s ease-in-out;
                    svg {
                        fill: #fff;
                    }
                }
                &:focus {
                    outline: none;
                    border: none;
                    box-shadow: none !important;
                }
            }
        }