.services-intro {
    padding: 0px 30px; 
    span{
        background-color: $primary; 
        color: $light; 
        font-size: 200%; 
        display: block;
        line-height: 2; 
        text-align: center;
        margin-right: 10px; 
        height: 2em; 
        width: 2em; 
        float: left; 
    }

    .hz-line {
        width: 200px; 
        height: 1px; 
        border-bottom: $primary solid 3px; 
        margin: 60px auto; 
    }
}

.service-icon { 
    background-color: $primary;
    width: 64px; 
    height: 64px;
    border-radius: 50%;
    img {
        display: block; 
        width: 45px; 
        height: 45px; 
    }
    svg {
        display: block; 
        width: 35px; 
        height: 35px; 
    }
}

.service-anchors-box {
    p {
        font-size: 16px; 
        color: $primary-2; 
    }
    a {
        display: block; 
    }
}

.services{
    h2{
        font-size: 40px; 
        font-family: $font-family; 
        @media(max-width:1300px){
            text-align: center !important; 
            font-size: 30px; 
        }
        @media(max-width:800px){
            font-size: 40px;
        }
        @media(max-width:700px){
            font-size: 25px; 
        }
    }
    .interior-box {
        border: $light solid 3px; 
        padding: 120px 0px; 
        @media(max-width:800px){
            padding: 80px 0px; 
        }
    }
    .service {
        background-color: transparent !important; 
        position: relative;
        background-size: cover !important; 
        background-attachment: fixed !important; 
        background-repeat: no-repeat !important; 
        left: 0px;  
        width: 100%; 
        min-width: 100%; 

        .dark-overlay {
           background-color: rgba(40, 40, 40, 0.9) !important;
        }
    
        .light-overlay{
            background-color: rgba(255, 255, 255, 0.9) !important; 
        }
            
        .description { 
            width: 100%; 
            height: 100%; 
            padding: 7%; 
        }
    }
    .sub-services{
        h3 {
            width: 100%; 
            font-family: $font-family-secondary; 
            font-size: 15px;
            line-height: 45px; 
            padding: 0px 35px; 
            margin-bottom: 10px; 
            letter-spacing: 2px; 
            font-weight: bold;   
            white-space: nowrap; 
            overflow-x: hidden; 
            &:hover {
                background-color: $light !important; 
                color: $primary !important;
                transition: all 0.3s ease; 
            }
            @media(max-width:700px){
                font-size: 10px; 
            }
        }
    }
}

.modal.fade {
    .close-btn {
        font-weight: 100; 
        color: #fff; 
        position: fixed; 
        opacity: 0.9;
        cursor: pointer; 
        float: none; 
        z-index: 1060;
        @media(min-width: 769px){
            font-size: 8rem !important;
            top: -40px; 
            right: 35px; 
        }
        @media(max-width: 768px){
            font-size: 3rem !important;
            top: -10px;
            right: 10px;
        }
    }
}

.modal-dialog {
    max-width: 70% !important;
    @media(min-width: 450px) {
        margin: 1.75rem auto !important;
    }
    @media(max-width: 450px) {
        margin: 0.5rem auto !important;
    }
    .modal-header {
        border-bottom: none !important;
    }
    .modal-title {
        font-size: 26px;
        margin-bottom: 0;
        padding: 20px;
        font-family: 'Montserrat';
        color: #000;
    }
    .modal-body {
        padding: 0px 20px 15px 20px;
        p {
            color:#5a5a5a;
            font-family: 'Montserrat';

        }
        p.font-weight-bolder {
            margin: 0;
        }
    }
    .modal-footer {
            border-top: none;
        ul {
            padding-left: 1rem !important;
            li {
            font-family: 'Montserrat';
            color:#5a5a5a;
            font-size: 14px;
            }
        }
    }
    .portable-dev-modal-content,
    .data-preserv-modal-content,
    .forensic-analysis-modal-content,
    .contractual-comp-modal-content,
    .cash-flow-modal-content,
    .damage-analysis-modal-content,
    .financial-trans-modal-content,
    .surveillance-modal-content,
    .investigations-modal-content {
        background: #ffffff;
        border-style: solid;
        border-width: 20px;
        border-radius: 0px;
        border-color: #8e052a;
        @media(min-width: 581px) {
            padding: 50px;
        }
        @media(max-width: 580px) {
            padding: 20px;
        }
        @media(max-width: 425px) {
            padding: 0;
            border: none !important;
        }
    }
}

.data-preserv-modal-content,
.damage-analysis-modal-content,
.contractual-comp-modal-content,
.damage-analysis-modal-content,
.financial-trans-modal-content,
.surveillance-modal-content,
.investigations-modal-content {
    .modal-header {
        justify-content: center;
        @media(max-width: 488px) {
            padding: 2rem 0;
        }
        .modal-title {
            @media(max-width: 488px) {
                padding: 0;
            }
        }
    }
}




