* {
    scroll-behavior: smooth !important;

    @media (max-width:390px) {
        overflow-x: hidden;
    }
}

.header-hero#hero-container {
    display: flex;
    background-color: #000;
    justify-content: center;
    width: 100%;
    height: 900px;
}

#hero-inner {
    transition: transform 0.5s;
}

.firm-overview {
    a{
        text-decoration: none; 
        display: block; 
        font-style: normal; 
        color: #0a0a0a;
        &:hover {
            color: $light; 
        }
    }
    h2 {
        color: #0a0a0a;
        font-family: $font-family; 
        font-weight: bold; 
        font-size: 24px; 
        margin: 80px 0;  
        letter-spacing: 2px; 

    }


    .overview-image-chunk {
        border: $primary solid 10px; 
        margin-bottom: 50px; 

    }

    h3 {
        position: absolute; 
        bottom: 0;
        font-size: 20px; 
        font-weight: bold;
        line-height: 24px; 
        margin-bottom: 0;
        font-family: $font-family;
        width: 100%;
        padding: 15px;
        vertical-align: bottom;
        text-align: end; 
    }

    .overview-image {
        min-height: 176px; 
        background-size: cover; 
        background-position: center; 
        width: 373.33px;
            @media(min-width: 1200px) {
                height: 176px; 
            }
            @media(max-width: 1199px) {
                height: 300px;
            }
        overflow: hidden !important;
        position: relative;
            &.first-image:hover,
            &.second-image:hover,
            &.third-image:hover,
            &.fourth-image:hover, {
                a {
                    @media(min-width: 1200px) {
                        width: 440px; 
                        height: 220px;
                    }
                    @media(max-width: 1199px) {
                        width: 100%; 
                        height: 100%;
                    }
                .overlay {
                    background:rgba(40, 47, 102, 0.7);
                }
            }
        }
        a.image-1,
        a.image-2,
        a.image-3,
        a.image-4,
        a.image-5,
        a.image-6 {
            background-size: cover; 
            background-position: center;
            cursor: pointer !important;
            @media(min-width: 1200px) {
                width: 487.77px;
                height: 234.66px;
            }
            @media(max-width: 1199px) {
                width: 110%;
                height: 110%;
            }
            position: absolute;
            right: 0;
            bottom: 0;
            transition: 0.4s;
            .overlay {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                height: 100%;
                background:rgba(40, 47, 102, 0.4);
                transition: 0.4s ease-in-out;
            }
            h3 {
                bottom: 60px;
            }
        }
        .image-1 {
            background: url('/assets/img/800-street-1024x492.jpg');
        }
        .image-2{
            background:  url('/assets/img/800-bridge-cables-darker-1024x492.jpg');
        }
        .image-3{
            background: url('/assets/img/800-manhattan-1024x492.jpg');
        }
        .image-4{
            background: url('/assets/img/800-subway-1024x492.jpg');
        }
        .image-5{
            background: url('/assets/img/800-workplace-1024x492.jpg');
            h3{
                text-align: center !important; 
                } 
            }
        .image-6{
            background: url('/assets/img/800-mentorships-1024x492.jpg');
            h3{
            text-align: center !important; 
            }
        }
    }
}

@media(max-width: 799px) {
    .sidebar-content-menu {
        display: block;
        cursor: none !important;
    }
    div {
        ul li {
            color: rgba(255,255,255,0.10);
            &:hover span.menu-number {
                cursor: none !important;
                color: transparent !important;
            }
        }
    span.menu-item {
        color: rgba(255, 255, 255, 0.2);
        &:hover {
            cursor: none;
            color: transparent;
        }
    }
        span.menu-number {
            color: rgba(255, 255, 255, 0.2);
            &:hover {
                cursor: none;
                color: transparent;
            }
        }
    }
}

    @media(min-width: 800px) {
        section.header-hero {
            .sidebar-content-menu {
                transition: 0.4s all ease-in-out;
                width: 90px;
                z-index: 9;
                top: 58px;
            }
            &:hover {
                .sidebar-content-menu {
                border-right: 2px solid rgba(255,255,255,0.10);
                background-color: rgba(6, 6, 6, 0.4);
                opacity: 1;
                top: 58px;
                &:hover {
                    width: 130px;
                }
            div {
                ul li {
                    color: rgba(255,255,255,0.10);
                    &:hover span.menu-number {
                        color: #fff !important;
                        }
                    }
                }
                span.menu-number {
                    color: rgba(255, 255, 255, 0.2);
                    &:hover {
                        color: #fff !important;
                    }
                }
            }
        }
    }
}

@media(min-width: 1199px) {
    .sidebar-content-menu {
        top: 0 !important;
        height: 900px !important;
    }
}

    .sidebar-content-menu {
        position: absolute;
        border: transparent;
        background: rgba(200, 9, 63, 0);
        padding: 0px;
        height: 900px;
        left: 0px;
        ul {
            li {
                font-family: "proxima-nova", Arial, san-serif !important;
                font-weight: bold !important;
                font-size: 20px !important;
                text-transform: uppercase;
                width: 250px;
                position: relative;
                left: 229px;
                height: 30px;
                margin: 0.5rem;
                cursor: pointer;
                transition: 0.4s ease-in-out;
                span {
                    color: transparent;
                    transition: 0.2s ease-in-out;
                }
                    &:hover span.menu-number {
                        border-right: 3px solid #8e052a;
                        color: #fff;
                        }
                        &:hover span.menu-item {
                            color: #fff;
                        }
                    }
                }       
            }
                    
            #slideshow {
                margin: 0 0 1rem 0;
                position: relative;
                top: 0;
                width: 100%;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
                .carousel {
                    height: 900px;
                    overflow: hidden;
                    .carousel-inner {
                            .carousel-item {
                                transition-duration: 1s !important;
                                &.black-background {
                                    background-color: #000 !important;
                                    width: 100%;
                                    height: 100%;
                                }
                                &.active {
                                    z-index: 1 !important;
                                    opacity: 1 !important;
                                }
                                &:not(.active) {
                                    z-index: 0 !important;
                                    opacity: 0 !important;
                                }
                            }
                        .content-1 {
                            .content-1-text {
                                overflow: visible;
                                transform-style: preserve-3d;
                                .hero-border {
                                    overflow: visible;
                                    position: absolute;
                                    transform: translateZ(-5px);
                                    border-color: rgb(142, 5, 42);
                                    border-style: solid;
                                    border-width: 16px;
                                    height: 330px;
                                    width: 330px;
                                }
                            a {
                                position: fixed;
                                color: #fff;
                                transition: 0.3s;
                                bottom: -200px;                        
                                &:hover {
                                    color: #8e052a;
                                    cursor: pointer !important;
                                   }
                               }
                               h6 {
                                overflow: visible;
                                font-weight: 600;
                                font-family: proxima-nova;
                                letter-spacing: 1px;
                                position: absolute;
                                top: -25px;
                                transform: translateZ(-4px);
                                span {
                                    color:rgb(142, 5, 42); 
                
                                    }
                                }
                                h1 {
                                    overflow: visible;
                                    margin-top: 50px;
                                    transform: translateZ(-4px);
        
                                }
                                p {  
                                    overflow: visible;      
                                    transform: translateZ(-2px);
                                    font-family: "proxima-nova";
                                    font-weight: 500;
                                }
                            }
                        }
                        .content-2 .content-2-text,
                        .content-3 .content-3-text, 
                        .content-4 .content-4-text {
                            a {
                                font-weight: 700;
                                font-family: proxima-nova, Arial, sans-serif;
                                cursor: pointer;
                                color: rgb(255, 255, 255);
                                border-style: solid;
                                border-width: 2px;
                                border-color: rgb(255, 255, 255);
                                background-color: rgba(0, 0, 0, 0);
                                line-height: 14px;
                                border-radius: 0;
                                padding: 15px 20px;
                                letter-spacing: 1px;
                                font-size: 14px;
                                @media(max-width: 600px) {
                                    padding: 10px 15px;
                                    font-size: 11px;
                                }
                                &:hover {
                                    color: #000 !important;
                                    background-color: #fff;
                                    cursor: pointer !important;
                                    transition: 0.4s ease-in-out;
                                } 
                            }
                            .line {
                                width: 4px;
                                height: 78px; 
                                background-color: #8e052a;
                            }
                        }
                        .content-2 .content-2-text {
                            height: 330px;
                            width: 330px;
                            @media(max-width: 600px) {
                                h1 {
                                    top: 5px !important;
                                    font-size: 26.5px;
                                }
                            }
                        }
                        .content-3 .content-3-text {
                            height: 330px;
                            width: 370px;
                            @media(min-width: 801px) {                        
                                h1 {
                                    font-size: 53px !important;
        
                                }
                            }
                            @media(max-width: 800px) {                        
                                h1 {
                                    font-size: 39.75px !important;
                                }
                                a {
                                    letter-spacing: 1px;                                          
                                    padding: 10px 15px !important;
                                    font-size: 11px !important;                            
                                }
                            }
                            h6.content-three {
                                font-size: 20px;
                                font-family: proxima-nova;
                                font-weight: 600;
                                letter-spacing: 1px;
                            @media(max-width: 800px) {
                                font-size: 14px !important;
                            }
                            @media(max-width: 500px) {
                                    position: absolute;
                                    top: -2px !important;
                                    width: 100% !important;
                                }
                            }
                        }
                        .content-1 .content-1-text {
                            @media(max-width: 800px) {
                                height: 247.5px;
                                width: 247.5px;
                            }
                            @media(max-width: 500px) {
                                height: 165px;
                                width: 165px;
                            }
                        }
                        .content-1 .content-1-text,
                        .content-2 .content-2-text,
                        .content-3 .content-3-text,
                        .content-4 .content-4-text {
                                position: absolute;
                                top: 30%;
                                background-color: rgba(0, 0, 0, 0);
                                line-height: 20px;
                                opacity: 1;
                        }
                        .content-1 .content-1-text,
                        .content-2 .content-2-text {
                            h1 {  
                                white-space: nowrap; 
                                text-align: center; 
                                font-weight: 700;
                                font-size: 53px;  
                                @media(max-width: 800px) {
                                    font-size: 39.75px;
                                }
                                @media(max-width: 600px) {
                                    font-size: 26.5px;
                                }
                                @media(max-width: 500px) {                                            
                                    position: relative;
                                    top: -25px;                
                                }
                            }
                            p,
                            p.hero-content-1-desc {
                                white-space: normal;
                                font-size: 14px;
                                line-height: 18px;
                                @media(min-width: 801px) {
                                    width: 50%;
                                }
                                @media(max-width: 800px) {
                                    width: 130%;
                                    bottom: -10px; 
                                }
                                @media(max-width: 500px) {  
                                    width: 150%;                                  
                                }
                            }
                            h6:not(.content-three) {
                                font-family: proxima-nova;
                                font-weight: 600;
                                letter-spacing: 1px;
                                @media(max-width: 800px) {                        
                                    top: -20px;
                                    width: 170%;
                                }
                                @media(max-width: 500px) {                        
                                    position: relative;
                                    top: 0;                
                                }
                                span {
                                    color:rgb(142, 5, 42); 
                                }
                            }
                        } 
                    }
                       .content-4 {
                           .content-4-text {
                                overflow: visible;
                                height: 430px;
                                width: 330px;
                                @media(max-width: 800px) {
                                    width: 247.5px;
                                }
                               .smithsonian {
                                @media(min-width: 601px) {
                                    width: 253px;
                                    height: 67px;
                                   }
                                   @media(max-width: 600px) {
                                    width: 189px !important;
                                    height: 50px !important;
                                    font-size: 39.75px !important;
                                   }
                               }
                               h6 {
                                position: relative;
                                top: -30px !important;
                                font-size: 14px !important;
                                font-family: proxima-nova;
                                font-weight: 600;
                                letter-spacing: 1px;
                                    @media(max-width: 600px) {
                                        font-size: 14px !important;
                                        }
                                    }
                                h1 {  
                                    white-space: nowrap; 
                                    text-align: center; 
                                    font-weight: 700;
                                    font-size: 53px;  
                                    position: relative;
                                    top: -10px;
                                    @media(max-width: 600px) {
                                        font-size: 39.75px;
                                    }
                                    @media(max-width: 500px) {                                            
                                        position: relative;
                                        top: -15px;                
                                    }
                                }
                            }
                        }  
                    }
                }
        