.no-padding-tablet{
    @media(max-width:800px){
        padding: 0px 0px 20px 0px !important; 
    }
}

.career-intro {
    border: $primary solid 10px; 
    background-image: url('/assets/img/lady_box.jpg');
    background-position: left top; 
}

.career-intro-text {
    border: $light solid 3px; 
    @media(max-width: 800px){
        border: none; 
    }
    h2 {
       font-family: $font-family; 
       font-size: 40px; 
       line-height: 55px; 
       font-weight: normal; 
       margin-bottom: 30px; 
       @media(max-width:800px){
            text-align: left !important; 
       }
       @media(max-width:500px){
           font-size: 25px;
           margin-bottom: 15px;
       }
       @media(max-width:400px){
        padding-right: 30px !important; 
        padding-left: 30px !important; 
       }
   }
   p {
       line-height: 1.6; 
       @media(max-width:400px){
        padding-right: 30px !important; 
        padding-left: 30px !important; 
       }
   }
}

.careers-form-embed {
    ._form_1 {
        max-width: 100%;
    }
    h2 {
        font-size: 20px;
    }
}

.careers-content {
    margin-top: 30px;
}

.no-padding-tablet {
    .openings-block {
        font-family: $font-family;
        background: #FAFAFA;
        padding: 30px 10px; 
        border: 5px solid $primary; 
        font-size: 16px;
        color: #5a5a5a;
        a:hover {
            text-decoration: none;
            color: $primary;
        }
    }
}
