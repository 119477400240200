input {
    &:focus {
        outline: none;
    }
}

.nav-section {
    height: 58px;
    position: fixed;
    left: 0;
    z-index: 3;
    width: 100%;
    @media(max-width: 1199px) {
       position: relative;
    }
    a {
         img.header-logo {
                height: 77px;
                position: absolute;
                top: 0;
                left: 10px;
                @media(max-width: 1199px) {
                    display: none;
                }
              }
              img.header-logo-small {
                  display: none;
                @media(max-width: 1199px) {
                    display: block;
                    width: 47px;
                    height: 38px;
                }
              }
            }
        }
    
    .transparent-background {
        background-color: #0a080866;
        transition: 0.3s ease-in-out;
    }

    .search-overlay {
        position: fixed;
        display: none;
        left: 0;
        width: 5000px;
        height: 5000px;
        background-color: rgba(27,27,27,0.95);
        z-index: 3;
        .row {
            position: fixed;
            top: 50%;
            left: 0;
            width: 100%;
            border-bottom: 1px solid rgba(255,255,255,0.2);
            .search-bar {
                width: 100%;
                input[type="text"] {
                    position: relative;
                    font-size: 40px;
                    font-family: "Montserrat";
                    line-height: 55px;
                    padding: 0 1rem;
                    background: transparent;
                    border: none;
                    z-index: 1;
                    width: 92%;
                    color: #fff;
                }
                button {
                    svg {
                        fill: #fff;
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .search-overlay {
        position: fixed;
        display: none;
        left: 0;
        width: 5000px;
        height: 5000px;
        background-color: rgba(27,27,27,0.95);
        z-index: 3;
        .click-to-hide:first-of-type {
            height: 56%;
        }
        .click-to-hide.bottom-hide {
            position: absolute;
            bottom: 0;
            height: 42%;
        }
        .row {
            position: fixed;
            top: 50%;
            left: 0;
            width: 100%;
            border-bottom: 1px solid rgba(255,255,255,0.2);
            .search-bar {
                width: 100%;
                input[type="text"] {
                    position: relative;
                    font-size: 40px;
                    font-family: "Montserrat";
                    line-height: 55px;
                    padding: 0 1rem;
                    background: transparent;
                    border: none;
                    z-index: 1;
                    width: 92%;
                    color: #fff;
                }
                input[type="text"]::placeholder {
                    color: #fff;
                }
                button {
                    svg {
                        fill: #fff;
                        cursor: pointer;
                    }
                }
                ul li a {
                    color: #fff !important;
                    font-family: Montserrat;
                    cursor: pointer;
                }
            }
        }
    }

    .scroll-logo {
        display: none;
            img {
                visibility: hidden;
                @media(max-width: 1199px) {
                        display: none;
                }
            }
        &.scrolled {
            display: flex;
                img {
                    visibility: visible;
                    width: 47px;
                    height: 38px;
                }
            }
        }
    
//   nav.header-nav {
//       ul {
//           li {
//             padding: 0.25rem !important;
//              &.active {
//                 border: 1px solid rgba(255,255,255,0.1);
//              } 
//               a {
//                 font-style: normal;
//                 font-family: "Montserrat";
//                 font-weight: 700;
//                 text-transform: uppercase;
//                 font-size: 12px !important;
//                     &:hover {
//                         color: #8e052a !important;
//                         transition: color 0.3s ease-in-out;
//                         text-decoration: none;
//                         opacity: 1 !important;
//                     }
//                 }
//             }
//         }
//     }

    nav {
        @media(max-width: 1199px) {
            z-index: 11;
            .navbar-collapse {
                max-width: 260px !important;
                position: fixed;
                top: 0;
                width: 100%;
            }
        }
        .navbar-toggler {
            display: none;
            &:focus {
                outline: none !important;
            }
            .navbar-toggler-icon {
                height: 1px;
                background-color: #28262b;
                &.top {
                    width: 25px;
                }
                &.middle {
                    width: 20px;
                    margin-top: 4px;
                }
                &.bottom {
                    width: 31px;
                    margin-top: 4px;
                }
            }
        }
        @media(max-width: 1199px) {
            background-color: #fff;
            position: relative;
            .navbar-toggler {
                display: block !important;
            }
        }
        ul.navbar-nav {
            li {
                @media(max-width: 1199px) {
                    width: 100%;
                }
                padding: 0.25rem !important;
                a {
                  font-style: normal;
                  font-family: "Montserrat";
                  font-weight: 700;
                  text-transform: uppercase;
                  font-size: 12px !important;
                  color: #fff;
                      &:hover {
                          color: #8e052a !important;
                          transition: color 0.3s ease-in-out;
                          text-decoration: none;
                          opacity: 1 !important;
                      }
                      &.active {
                        border: 1px solid rgba(255,255,255,0.1);
                        } 
                    }
                }
            }
        }

    .search-icon {
        .search-circle {
            @media(max-width: 480px) {
                display: none;
            }
            background-color: transparent;
            height: 42px;
            width: 42px;
            border-radius: 50%;
            display: inline-block;
            cursor: pointer;
            &:hover {
                opacity: 0.8;
            }
            @media(max-width: 1199px){
                background-color: #000;
            }
        }
      svg {
          fill: #fff;
          @media(max-width: 1199px){
            position: absolute;
            top: 10px;
            margin: 0 10px;
          }
        }
    }

    .nav-item {
        &.toggler {
            display: none;
            @media(max-width: 1199px) {
                display: list-item;
            }
        }
        .nav-link {
            letter-spacing: 2px;
        }
        .navbar-toggler.mobile {
            display: none;
             @media(max-width: 1199px) {
                display: list-item;
                .flex-column {
                    padding: 0.5rem;
                    .navbar-toggler-icon {
                        background-color: #fff;
                        }
                    } 
                }
            }
        }

    @media(max-width: 1199px) {
        ul#side-menu {
            li.nav-item:not(.toggler) {
                border-top: 1px dotted rgba(255,255,255,0.1);
                color: rgba(255,255,255,0.5);
                padding: 0.25rem 0.75rem !important;
                &:hover {
                    background: #161616;
                    transition: color 0.2s ease-in-out;
                    a {
                        color: #fff !important;
                        transition: color 0.2s ease-in-out;
                        text-decoration: none;
                        }
                    }
                .nav-link.active {
                    border: none;
                }
                a {
                    color: rgba(255,255,255,0.5) !important;
                    }
                }
            }
        }
    
    .collapse-search {
        display: none;
    }

    @media(max-width: 1199px) {
        .navbar-collapse {
            left: -350px;
            background-color: #1b1b1b;
            width: 260px;
            position: fixed;
            transition: 0.2s ease-in-out;
            transform: translateX(-100%);
            height: 100%;
            &.show {
                transform: translateX(350px);
                ul {
                    li:not(.toggler) {
                        padding: 0.25rem 0.75rem;
                    }
                    li.last-item {
                        border-bottom: 1px dotted rgba(255, 255, 255, 0.1);
                    }
                } 
                .collapse-search {
                    display: block;
                    form {
                        width: 100%;
                        input[type="text"] {
                            position: relative;
                            font-family: "Montserrat";
                            line-height: 55px;
                            border: none;
                            z-index: 1;
                            width: 100%;
                            height: 48px;
                            padding: 5px 15px;
                            margin-bottom: 0;
                            background: transparent;
                            border: 1px solid rgba(255,255,255,0.1);
                            border-radius: 3px;
                            color: #ffffff80 !important;
                        }
                            input[type="text"]::placeholder {
                                color: #ffffff80 !important;
                            }
                        button[type="submit"] {
                            position: absolute;
                            right: 10px;
                            top: 331px;
                            svg {
                                fill: #ffffff80 !important;
                                }
                            }
                        }
                    }
                }
            }
        }